import React, { useState } from 'react';
import axios from 'axios';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (varible, close) => {
    if (close === true){
      setIsMenuOpen(!isMenuOpen);
    }
    else {
      setIsMenuOpen(!isMenuOpen);
      getScheudleTime(varible);
    }
  };

  const [data, setData] = useState({key: []});
  const [time, setTime] = useState({key: []});



  const getData = async () => {
    try {
      setGroupMemory(inputValue);
      const response = await axios.post('https://nur-store.kz/back/data', 
        {group: inputValue}
      );
      setData(response.data);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      alert(error);
    }
  };


  const getScheudleTime = async (varible) => {
    try {
      const response = await axios.post('https://nur-store.kz/back/dayScheudle', {
        varible: varible
      });
      setTime(response.data);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      alert(error);
    }
  };


  const clearData = async () => {
    setData({key: []})
  }

  let width = "min-w-[150px] w-[150px]"
  
    const [inputValue, setInputValue] = useState('');
    const [groupMemory, setGroupMemory] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    
    const hints = ['РЭТ22-3', 'Т24-1', 'ОП24-1Б', 'П24-1Д', 'ТЗИ22-3', 'ОП24-1А', 'П24-1Б', 'ТЗИ24-1', 'П24-1А', 'П24-1ЖК', 'ЭВМ22-3КОГ', 'П24-1В', 'ИС24-1', 'П22-3Б', 'П22-3А', 'ИС22-3Б', 'ТМ22-3', 'П22-3ЕК', 'Т22-3А', 'П22-3В', 'П22-3Д', 'Т22-3Б', 'П22-3ЗК', 'П22-3ИК', 'П21-4КОГ', 'П22-3Г', 'П24-1Г', 'РЭТ24-1','П22-3ЖК','ИС22-3А', 'ЭВМ23-2В', 'П23-2Е', 'ИС23-2А', 'Т23-2А', 'П23-2Д', 'ЭВМ23-2Б', 'РЭТ23-2', 'ЭВМ23-2А', 'ИС21-4Б', 'П23-2А', 'П23-2В', 'ИС21-4В', 'П23-2Б', 'П23-2Г', 'ИС23-2Б', 'ИС21-4ГК', 'ИС21-4А', 'Т23-2В','Т23-2Б','П23-2ЖК'];

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value) {
            const filteredSuggestions = hints.filter(hint =>
                hint.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const selectSuggestion = (hint) => {
        setInputValue(hint);
        setSuggestions([]);
    };

  return (
    <>
      <div className="p-5 flex flex-col mb-4 md:flex-row md:mb-30 gap-5">
        <div>
          <button onClick={getData} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Получить расписание
          </button>
        </div>
        <div>
          <button onClick={clearData} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Очистить расписание
          </button>
        </div>
        <div className="relative">
            <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder="Группа . . ."
                className="border border-gray-300 rounded p-2 w-full"
            />
            {suggestions.length > 0 && (
                <div className="absolute bg-white border border-gray-300 rounded mt-1 w-full z-10">
                    {suggestions.map((hint, index) => (
                        <div
                            key={index}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => selectSuggestion(hint)}
                        >
                            {hint}
                        </div>
                    ))}
                </div>
            )}
        </div>
      </div>
      <div className='overflow-x-auto w-full max-w-screen'>
      <table className="mt-4 mx-auto">
        <thead>
          <tr className="">
            <th className={`text-white bg-black ${width}`}>Понедельник</th>
            <th className={`text-white bg-black ${width}`}>Вторник</th>
            <th className={`text-white bg-black ${width}`}>Среда</th>
            <th className={`text-white bg-black ${width}`}>Четверг</th>
            <th className={`text-white bg-black ${width}`}>Пятница</th>
            <th className={`text-white bg-black ${width}`}>Суббота</th>
            <th className={`text-white bg-black ${width}`}>Воскресенье</th>
          </tr>
        </thead>
        <tbody className="">
            {Object.entries(data).map(([key, value]) => (
                <tr className=''>
                    {value.map((item, index) => (
                        <td className='align-top text-center'>
                            {item.map((item1, index1) => {
                             const varible = item[0];
                              return (
                                <div key={index1}>
                                  {index1 === 0 ? (
                                    <div id={index1} className={`hover:cursor-pointer p-1 bg-blue-500 text-white`}>
                                      {item1}
                                    </div>
                                  ) : (
                                    <div 
                                      id={varible + "%groupSeparetоr%" + groupMemory}
                                      onClick={() => toggleMenu(varible + "%groupSeparetоr%" + groupMemory)} 
                                      className={`hover:cursor-pointer p-1 border-2 border-black my-0.5 ${index1 === 4 ? 'bg-yellow-500' : 'bg-gray-100'}`}
                                    >
                                      {item1}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
      </table>
      </div>
      {isMenuOpen && (
        <>
          <div className="absolute inset-0 backdrop-blur-sm "></div>
            <div className="absolute top-1/2 left-5 right-5 transform -translate-y-1/2 bg-gray-200 rounded shadow z-20 p-1 h-[80%] items-center align-top flex flex-col">
              <table>
                <thead>
                  <tr>
                    <th className={`text-white bg-black min-w-[100px] w-[500px]`}>Кабинет</th>
                    <th className={`text-white bg-black min-w-[100px] w-[500px]`}>Предмет</th>
                    <th className={`text-white bg-black min-w-[100px] w-[500px]`}>Время</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(time).map(([key, value]) => (
                    value.map((item, index) => (
                      <tr className=''>
                        {item.map((item1, index1) => (
                            <td className={`align-top text-center text-white ${index === 3 ? 'bg-yellow-500' : 'bg-green-500'}`}>
                              {item1}
                            </td>
                        ))}
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
              <button onClick={() => toggleMenu(".", true)} className="mt-4 p-2 bg-red-500 text-white rounded">
                Закрыть
              </button>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
